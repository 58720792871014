import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { isBlank } from "../utils/isBlank"

export default function CandidateCard({
  name,
  surname,
  picture,
  area,
  candidateNumber,
  introduction,
  setCandidate,
  koulutus,
  ammattiTitteli,
}) {
  const [isWindowBlank, setWindowBlank] = useState(false)
  var defaultParameter = ""
  if (isWindowBlank) defaultParameter = "?blank=true"
  useEffect(() => {
    setWindowBlank(isBlank(window))
  })
  return (
    <div
      className="m-6 flex-none  w-60 bg-secondary  shadow-md overflow-hidden relative pb-2"
      onClick={() => {
        setCandidate()
        const url = new URL(window.location)
        url.searchParams.set("candidate", name + "_" + surname)
        window.history.pushState({}, "", url)
      }}
    >
      <div className="w-full h-full absolute transparent opacity-50 hover:bg-primary z-10" />
      <div className="relative">
        <GatsbyImage
          className=" z-0  w-60 h-60"
          imgClassName=" object-top "
          alt="candidate picture"
          image={picture.gatsbyImageData}
        />

        {candidateNumber && (
          <p className="absolute bottom-3 left-3 z-10 bg-primary text-3xl p-2 rounded-2xl text-white">
            {candidateNumber}
          </p>
        )}
      </div>
      <p className="font-bold text-2xl p-2 text-center">
        {name + " " + surname}
      </p>
      <p className="text-center text-sm">
        {[koulutus, ammattiTitteli].filter(t => t !== "").join(", ")}
      </p>
    </div>
  )
}
