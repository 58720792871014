import React, { useState, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"

import Background from "../../../components/Background.js"
import Candidates from "../../../components/Candidates.js"
import { isBlank } from "../../../utils/isBlank"

export default function Home({ data }) {
  return (
    <div>
      <Background>
        <Content data={data} />
      </Background>
    </div>
  )
}

function Content({ data }) {
  const [isWindowBlank, setWindowBlank] = useState(false)

  var defaultMargin = "-my-36 md:-my-56"
  if (isWindowBlank) defaultMargin = ""

  const candidates = data.allDatoCmsGeneralelectioncandidate.edges.map(
    node => node.node
  )

  const areas = JSON.parse(data.allDatoCmsWebsiteFile.edges[0].node.area).area

  useEffect(() => {
    setWindowBlank(isBlank(window))
  })

  return (
    <div className={" md:w-4/5 xl:max-w-7xl " + defaultMargin}>
      {!isWindowBlank && (
        <a href="https://liikenyt.fi">
          <GatsbyImage
            className="w-36 "
            alt="Liike Nyt logo"
            image={
              data.allDatoCmsWebsiteFile.edges[0].node.logo.gatsbyImageData
            }
          />
        </a>
      )}
      <div className="bg-secondary h-auto shadow-2xl mb-8 ">
        <Title />
        <Candidates areas={areas} candidatesList={candidates} />
      </div>
    </div>
  )
}

function Title() {
  return (
    <div className="p-12 ">
      <h1 className="text-2xl text-center sm:text-3xl md:text-6xl font-bold mb-2">
        Eduskuntavaaliehdokkaat
      </h1>
      <div className="md:w-12/12 md:m-auto text-base">
      <p className="pb-2">Talous ei kasva, koulutustulokset sukeltavat, sote-sektori on kriittisesti aliresurssoitu. Vanhojen puolueiden harjoittama politiikka ei toimi.</p>
      <p className="pb-2">Liike on uusi vaihtoehto. Liike on muutos. Suomi on saatava takaisin kasvun ja kehityksen tielle.</p>
<p className="pb-2">Liikkeen ehdokkaat tulevat erilaisista taustoista. Se on Liikkeen rikkaus. Ei ole yhtä ja oikeaa ehdokastyyppiä. Liike on vaikuttamiskanava mahdollisimman monelle. Tärkein ominaisuus, jonka ehdokkaamme jakavat on halu vaikuttaa ja saada aikaan muutosta.</p>
<p>Eduskuntavaalit järjestetään 2.4.2023. Tutustu Liikkeen ehdokkaisiin ja löydä omasi.</p>
      </div >
    </div>
  )
}

export const query = graphql`
  query GalleryQuery {
    allDatoCmsWebsiteFile {
      edges {
        node {
          municipality
          area
          logo {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
        }
      }
    }
    allDatoCmsGeneralelectioncandidate {
      edges {
        node {
          id
          name
          age
          electionarea
          koulutus
          ammattiTitteli
          blog
          campaignDescription
          email
          facebook
          instagram
          municipality
          personalDescription
          candidatenumber
          surname
          picture {
            gatsbyImageData
          }
        }
      }
    }
  }
`
