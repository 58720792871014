import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

export default function FetchedCandidateCard({
  name,
  surname,
  picture,
  area,
  candidateNumber,
  introduction,
  setCandidate,
  koulutus,
  ammattiTitteli,
}) {
  const [img, setImg] = useState(
    <img
      className="block z-0"
      alt={name + " " + surname}
      src="./../../loading-animation-2.gif"
    />
  )

  useEffect(() => {
    async function fetchData() {
      const url = await fetchImage(picture)
      setImg(
        <img
          className="block z-0 w-60 h-60 object-cover object-top"
          alt={name + " " + surname}
          src={url}
        />
      )
    }
    fetchData()
  }, [name, picture, surname])

  return (
    <div
      className="m-6 flex-none  w-60 bg-secondary  shadow-md overflow-hidden relative pb-2"
      onClick={() => {
        setCandidate()
        const url = new URL(window.location)
        url.searchParams.set("candidate", name + "_" + surname)
        window.history.pushState({}, "", url)
      }}
    >
      <div className="w-full h-full absolute transparent opacity-50 hover:bg-primary z-10" />
      <div className="relative">
        {img}
        {candidateNumber && (
          <p className="absolute bottom-3 left-3 z-10 bg-primary text-3xl p-2 rounded-2xl text-white">
            {candidateNumber}
          </p>
        )}
      </div>
      <p className="font-bold text-2xl p-2 text-center">
        {name + " " + surname}
      </p>
      <p className="text-center text-sm">
        {[koulutus, ammattiTitteli].filter(t => t !== "").join(", ")}
      </p>
    </div>
  )
}

async function fetchImage(id) {
  const { SiteClient } = require("datocms-client")
  const client = new SiteClient("7617f586a3abb029f54ca4026bcae5")

  const image = await client.uploads.find(id)
  return image.url
}
