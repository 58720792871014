import React, { useState, useEffect, useRef } from "react"
import { Fragment } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"
import Content from "../components/page/candidate/Content"

import CandidateCard from "./CandidateCard.js"
import FetchedCandidateCard from "./FetchedCandidateCard.js"

export default function Candidates({ candidatesList, areas }) {
  const [candidate, setCandidate] = useState(undefined)
  useEffect(() => {
    const candidateName = new URLSearchParams(window.location.search).get(
      "candidate"
    )

    setCandidate(
      candidateName
        ? candidatesList.filter(c => {
          const cNames = candidateName.split("_")
          return c.name === cNames[0] && c.surname == cNames[1]
        })[0]
        : undefined
    )
  }, [])

  var candidatesWithNumber = []
  var candidatesWithoutNumber = []
  candidatesList.forEach(el => {
    if (el.candidatenumber) candidatesWithNumber.push(el)
    if (!el.candidatenumber) candidatesWithoutNumber.push(el)
  })
  const [candidates, setCandidates] = useState(
    generateCandidates(
      [
        ...candidatesWithNumber.sort(sortByCandidateNumber),
        ...candidatesWithoutNumber,
      ].sort(sortBySurname),
      setCandidate
    )
  )

  var candidatesIds = useRef(
    candidatesList.map(candidate => candidate.id.substring(32, 41))
  )

  const [areaSel, setAreaSel] = useState("Kaikki vaalipiirit")

  useEffect(() => {
    async function fetchData() {
      const newCandidates = await fetchCandidate()

      const filteredCandidates = newCandidates.filter(
        candidate => !candidatesIds.current.includes(candidate.id)
      )
      const filteredCandidatesIds = filteredCandidates.map(
        candidate => candidate.id
      )

      candidatesIds.current = candidatesIds.current.concat(
        filteredCandidatesIds
      )

      setCandidates([
        ...candidates,
        generateFetchedCandidates(filteredCandidates, setCandidate),
      ])
    }
    const area = new URLSearchParams(window.location.search).get("vaalipiiri")
    setAreaSel(area ? area : "Kaikki vaalipiirit")
    if (area) {
      window.area = area
    }
    fetchData()
  }, [])

  return (
    <div className="">
      {candidate ? (
        <div className="fixed z-50 flex justify-center left-0 right-0 top-0 w-screen h-full bg-black/50">
          <div className=" xl:mt-28 ">
            <Content
              candidate={candidate}
              setCandidate={() => setCandidate(undefined)}
            />
          </div>
        </div>
      ) : null}
      <div className="flex flex-col pb-6">
        <Example
          areas={["Kaikki vaalipiirit", ...areas]}
          setSelected={setAreaSel}
          selection={areaSel}
        />
        <div className="flex flex-wrap justify-around">
          {candidates.filter(candidate => {
            if (areaSel !== "Kaikki vaalipiirit") {
              if (candidate.props) return candidate.props.area === areaSel
              return false
            }
            return true
          })}
        </div>
      </div>
    </div>
  )
}

function sortByCandidateNumber(first, second) {
  if (first.candidatenumber === null) return 1
  return first.candidatenumber - second.candidatenumber
}

function sortBySurname(first, second) {
  if (first.surname.toUpperCase() < second.surname.toUpperCase()) {
    return -1
  }
  if (first.surname.toUpperCase() > second.surname.toUpperCase()) {
    return 1
  }

  return 0
}

function generateCandidates(newCandidates, setCandidate) {
  return newCandidates.map(candidate => {
    return (
      <CandidateCard
        key={candidate.id}
        name={candidate.name}
        surname={candidate.surname}
        picture={candidate.picture}
        area={candidate.electionarea}
        municipality={candidate.municipality}
        candidateNumber={candidate.candidatenumber}
        introduction={candidate.personalDescription}
        setCandidate={() => setCandidate(candidate)}
        koulutus={candidate.koulutus}
        ammattiTitteli={candidate.ammattiTitteli}
      />
    )
  })
}

function generateFetchedCandidates(newCandidates, setCandidate) {
  return newCandidates.map(candidate => (
    <FetchedCandidateCard
      key={candidate.id}
      name={candidate.name}
      surname={candidate.surname}
      picture={candidate.picture.uploadId}
      area={candidate.electionarea}
      municipality={candidate.municipality}
      candidateNumber={candidate.candidatenumber}
      introduction={candidate.personalDescription}
      setCandidate={() => setCandidate(candidate)}
      koulutus={candidate.koulutus}
      ammattiTitteli={candidate.ammattiTitteli}
    />
  ))
}

async function fetchCandidate() {
  const { SiteClient } = require("datocms-client")
  const client = new SiteClient("7617f586a3abb029f54ca4026bcae5")

  return client.items.all({
    filter: {
      type: "generalelectioncandidate",
    },
  })
}

function Selection({ area, setArea, selection }) {
  const textStyle = " text-xl md:text-3xl text-center font-bold "
  return (
    <select
      className={"w-11/12 m-6 p-2" + textStyle}
      name="area"
      id="area"
      onChange={e => {
        window.area = e.target.value
        const url = new URL(window.location)
        url.searchParams.set("vaalipiiri", window.area)
        window.history.pushState({}, "", url)
        setArea(e.target.value)
      }}
      value={selection}
    >
      <option key="all" value="all">
        Kaikki hyvinvointialueet
      </option>
      {GenerateSelection(area)}
    </select>
  )
}

const GenerateSelection = arrayElements => {
  return arrayElements.map((el, index) => (
    <option key={index} value={el}>
      {el}
    </option>
  ))
}

function Example({ areas, setSelected, selection }) {
  return (
    <Listbox
      value={{ name: selection }}
      onChange={e => {
        window.area = e
        const url = new URL(window.location)
        url.searchParams.set("vaalipiiri", window.area)
        window.history.pushState({}, "", url)
        setSelected(e)
      }}
    >
      {({ open }) => (
        <>
          <div className="relative mt-1 md:ml-4 xl:w-1/4 w-full pr-12 z-40">
            <Listbox.Button className="relative mx-6 w-full cursor-default rounded-md border border-gray-300 bg-white py-6 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm ">
              <span className="block truncate">{selection}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 ">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm mx-6">
                {areas.map((area, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={area}
                  >
                    {({ selected, active }) => {
                      return (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {area}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
